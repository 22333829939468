import * as React from "react";
import styled from "styled-components";
import Container from "../components/common/Container";

const TermsStyled = styled.div`
  padding: 24px;
`;
const Terms = () => {
  return (
    <Container>
      <TermsStyled>
        <h1>USLOVI KORIŠĆENJA</h1>
        <p>Verzija: 1.0</p>
        <p>Datum: 01.06.2022. godine</p>
        <h2>(“USLOVI”)</h2>
        <p>
          Ovi Uslovi kori&scaron;ćenja (u daljem tekstu Uslovi), sa svim
          potencijalnim izmjenama, odnose se na kori&scaron;ćenje Konfidi
          mobilnog novčanika (u daljnjem tekstu: Aplikacija), softvera kreiranog
          za digitalizaciju loyalty i popust kartica i olak&scaron;avanje
          trgovine.
        </p>
        <p>
          Pristupom, otvaranjem naloga ili kori&scaron;ćenjem Aplikacije
          potvrđujete i slažete se da ste pročitali, razumjeli i složili se sa
          dolje navedenim uslovima i odredbama.
        </p>
        <p>
          Sve izmjene Uslova će biti pravovremeno objavljene na sajtu servisa
          &ldquo;Konfidi.me&rdquo;, sa primjerenim rokom stupanja na snagu od
          dana objavljivanja, te je Korisnik dužan da provjerava i da bude
          upoznat sa svim izmjenama Uslova.
        </p>
        <h2>DEFINICIJE</h2>
        <p>
          &ldquo;Konfidi&rdquo;, &ldquo;mi&rdquo; ili &ldquo;na&scaron;&rdquo;
          označava dru&scaron;tvo sa ograničenom odgovorno&scaron;ću Konfidi, sa
          sjedi&scaron;tem u Podgorici, koje je registrovano u skladu sa
          zakonima Crne Gore kod Centralnog registra privrednih subjekata Crne
          Gore.
        </p>
        <p>
          &ldquo;Aplikacija&rdquo; podrazumijeva izvorna softverska
          rje&scaron;enja kreirana za digitalizaciju loyalty i popust kartica,
          prilagođena za Apple i Android prenosne uređaje. Koristi se za izradu
          digitalnih kopija loyalty kartica, izradu novih digitalnih kartica i
          prikazivanje analitike potro&scaron;nje korisnicima.
        </p>
        <p>
          &ldquo;Korisnik&rdquo; ili &bdquo;Korisnici&ldquo; je pravno ili
          fizičko lice koje ispunjava kriterijume predviđene ovim Uslovima
          kori&scaron;ćenja, koji je pročitao i razumio Uslove i sa njima se u
          potpunosti složio prilikom registracije.
        </p>
        <h2>OPSEG I VRSTE USLUGA</h2>
        <p>
          Konfidi aplikacija se koristi za izradu digitalnih kopija loyalty
          kartica, izradu novih digitalnih kartica i prikazivanje analitike
          potro&scaron;nje korisnicima.
        </p>
        <p>
          Aplikacija se preuzima sa App Store-a za korisnike iOS operativnog
          sistema, odnosno sa Google Play store-a za korisnike Android
          operativnog Sistema.
        </p>
        <p>
          Preuzimanjem i instalacijom aplikacije, Korisnik se obavezuje da
          sadržaj Aplikacije koristi isključivo za ličnu upotrebu i na sopstvenu
          odgovornost.
        </p>
        <h2>OBIM PRAVA</h2>
        <p>
          Korisnik koristi Aplikaciju isključivo na svoj rizik.
          Kori&scaron;ćenjem sadržaja Aplikacije korisnik prihvata sve rizike
          koji mogu da nastanu iz njegovog kori&scaron;ćenja.
        </p>
        <p>
          Korisnik ne smije dijeliti ili učiniti Aplikaciju dostupnom trećim
          stranama (osim u mjeri u kojoj to dopu&scaron;taju Uslovi), te da bez
          prethodnog pristanka Konfidi d.o.o, prodavati, iznajmljivati,
          posuđivati ili na drugi način distribuirati aplikaciju.
        </p>
        <p>
          Korisnik se obavezuje da će učiniti sve &scaron;to je u njegovoj moći
          da bilo koje treće lice ne dođe u posjed uređaja na kome je Aplikacija
          instalirana, kao i da nikome neće omogućiti neovla&scaron;ćeno
          upoznavanje sa njegovim ličnim nalogom na mobilnim aplikacijama.
        </p>
        <p>
          Korisnik je saglasan da neće koristiti obrnuti inženjering, povratnu
          montažu, rastavljanje, dekodiranje ili bilo koji drugi način za
          otkrivanje izvornog koda aplikacije ili bilo koje povezane
          tehnologije.
        </p>
        <p>
          Kr&scaron;enja gore navedenih obaveza, kao i poku&scaron;aj takvog
          kr&scaron;enja, mogu biti predmet krivičnog gonjenja i &scaron;tete.
        </p>
        <p>Sva prava koja nijesu izričito data su zadržana.</p>
        <h2>ZAŠTITA PODATAKA</h2>
        <p>
          Za kori&scaron;ćenje funkcionalnosti aplikacije, potrebna je prethodna
          registracija od strane Korisnika. Obrada podataka Korisnika za
          funkcionalnost same aplikacije obavlja se na osnovu pristanka.
        </p>
        <p>
          Korisniku se nakon instaliranja mobilne aplikacije, u toku procesa
          kreiranja korisničkog naloga, prikazuju Uslovi kori&scaron;ćenja i
          Politika privatnosti. Korisnik je dužan da ih pročita, razumije i da
          se sa njima saglasi, kao i da, prije početka kori&scaron;ćenja
          aplikacije, ukoliko je saglasan, d&acirc; svoju saglasnost
          obilježavanjem predviđenog polja, kao i da d&acirc; svoju saglasnost
          za obradu podataka o ličnosti koje unosi u aplikaciju.
        </p>
        <p>
          Unoseći svoje lične podatke u aplikaciju, Korisnik pristaje na obradu
          ličnih podataka u skladu sa Politikom privatnosti i ovim Uslovima.
        </p>
        <p>
          Korisnik se pristupom, otvaranjem naloga i kori&scaron;ćenjem
          Aplikacije izričito sagla&scaron;ava sa tim da Konfidi prikuplja, čuva
          i obrađuje prikupljene podatke, radi preciznijeg informisanja samog
          korisnika o statistici potro&scaron;nje, u svrhe marketin&scaron;kih i
          prodajnih aktivnosti, u svrhe korisničke podr&scaron;ke, kao i u svrhe
          segmentacije Korisnika usluga, istraživanja i analize trži&scaron;ta.
        </p>
        <p>
          Prihvatanjem Uslova kori&scaron;ćenja, Korisnik daje saglasnost za
          primanje obavje&scaron;tenja (notifikacija).
        </p>
        <p>
          Data saglasnost iz prethodnog stava može se u bilo koje doba opozvati.
          Možete iskoristiti otkazivanja i prigovaranja, kontaktirajući nas.
          Imajte na umu da ćemo od vas možda tražiti da potvrdite svoj identitet
          prije nego &scaron;to odgovorimo na takve zahtjeve. Ako podnesete
          zahtjev, mi ćemo se potruditi da vam odgovorimo &scaron;to je prije
          moguće.
        </p>
        <h2>ISKLJUČENJE ODGOVORNOSTI</h2>
        <p>
          Konfidi d.o.o. neće snositi bilo kakvu odgovornost za eventualnu
          &scaron;tetu nastalu neovla&scaron;ćenim kori&scaron;ćenjem uređaja
          povezanog s Aplikacijom ili neovla&scaron;ćenim pristupom
          personalizovanim podacima korisnika.
        </p>
        <p>
          Konfidi d.o.o, kao i njeni zapo&scaron;ljeni, predstavnici, partnerske
          kompanije, licencirani predstavnici, agenti Kompanije ili druga lica
          koja učestvuju u stvaranju, izradi, promociji ili koja na drugi način
          čine Aplikaciju i sadržaj aplikacije dostupnim, nijesu materijalno
          odgovorni za bilo kakvu kaznenu, posebnu, indirektnu ili posljedičnu
          &scaron;tetu (I) koja na bilo koji način može nastati
          kori&scaron;ćenjem Aplikacije, ili je na bilo koji način u vezi s
          kori&scaron;ćenjem ove Aplikacije, (II) za bilo koje radnje korisnika
          upotrebom ili zloupotrebom sadržaja Aplikacije, te za (III) bilo kakvu
          &scaron;tetu koja može biti prouzrokovana korisniku ili bilo kojoj
          trećoj strani u vezi s upotrebom ili zloupotrebom kori&scaron;ćenja
          sadržaja Aplikacije.
        </p>
        <p>
          Konfidi d.o.o. ne može garantovati, te ne može snositi nikakvu vrstu
          odgovornosti za sljedeće situacije:
        </p>
        <ul>
          <li>
            da prilikom kori&scaron;ćenja Aplikacije neće biti gre&scaron;aka
            i/ili da će ista funkcionisati bez prekida u svakom trenutku
          </li>
          <li>
            Ukoliko je do&scaron;lo do zloupotrebe Aplikacije od strane trećih
            lica.
          </li>
        </ul>
        <p>
          Konfidi d.o.o. zadržava pravo da ukine Aplikaciju i promijeni njen
          sadržaj. Konfidi d.o.o. ne preuzima nikakvu odgovornost za
          &scaron;tetu nastalu tim promjenama.
        </p>
        <h2>PRAVA NA INTELEKTUALNU SVOJINU</h2>
        <p>
          Softversko rje&scaron;enje/algoritam Aplikacije, kao i prava
          intelektualne svojine (uključujući autorska prava) koja se odnose na
          sadržaj i informacije kao i materijal na Aplikaciji i baze podataka, u
          isključivom su vlasni&scaron;tvu Konfidi d.o.o.
        </p>
        <p>
          Konfidi d.o.o. zadržava vlasni&scaron;tvo nad svim pravima
          intelektualne svojine na softveru, algoritmu, žigovima, dizajnu
          (uključujući infrastrukturu) Aplikacije i Korisnici i treća lica
          nemaju pravo da kopiraju ili djelimično preuzimaju, postavljaju hiper
          linkove i linkove po dubini platforme, objavljuju, promovi&scaron;u,
          reklamiraju, integri&scaron;u, kombinuju ili na neki drugi način
          koriste sadržaj sa Aplikacije ili na&scaron; brend bez izričite pisane
          saglasnosti Konfidi d.o.o. Bilo kakvo nezakonito kori&scaron;ćenje
          Aplikacije ili kori&scaron;ćenje suprotno ovim Uslovima smatraće se
          povredom na&scaron;ih prava intelektualne svojine, pod prijetnjom
          materijalne i krivične odgovornosti za takvog korisnika.
        </p>
        <h2>OSTALE ODREDBE</h2>
        <p>
          Ovi uslovi i odredbe i njihovo tumačenje, kao i pružanje usluga
          Konfidi d.o.o, podliježu i u saglasnosti su sa zakonima Crne Gore.
          Ukoliko je bilo koja tačka ovih Uslova sada ili ubuduće nevažeća,
          neprimjenljiva ili neobavezujuća, i dalje će važiti sve ostale tačke.
        </p>
        <p>
          Na sve međusobne odnose između Konfidi d.o.o. i Korisnika koji nijesu
          uređeni ovim Uslovima kori&scaron;ćenja primjenjuju se pozitivni
          propisi Crne Gore.
        </p>
        <h2>IZMJENE OVIH USLOVA KORIŠĆENJA</h2>S vremena na vrijeme, možemo
        ažurirati naše Uslove korišćenja. Obavijestićemo vas o bilo kojoj
        promjeni objavljivanjem novih Uslova korišćenja na ovoj stranici.
        Prethodno ćemo vas obavijestiti putem e-pošte i/ili istaknutog
        obavještenja u okviru naše Usluge da promjena stupa na snagu i
        ažuriraćemo datum kod stavke “Zadnje ažuriranje” na početku ovih Uslova
        korišćenja. Savjetujemo vam da povremeno pregledate ove Uslove
        korišćenja radi izmjena. Izmjene ovih Uslova korišćenja stupaju na snagu
        datumom objavljivanja na ovoj stranici.
        <h2>KONTAKTIRAJTE NAS</h2>
        <p>
          Ako imate pitanja o ovim Uslovima korišćenja, možete nas kontaktirati
          e-poštom: info@konfidi.me
        </p>
        <p>
          Konfidi mobilni novčanik je proizvod Konfidi d.o.o. ©2022 Sva prava
          zadržana.
        </p>
      </TermsStyled>
    </Container>
  );
};

export default Terms;
